import { PropsWithChildren } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import cl from './Modal.module.css'

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

interface IModalProps {}

// FIXME: Fix Modal
const Modal = (props: PropsWithChildren<IModalProps>) => {
  const { children } = props

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div
      className={cl.modal_outside}
      onClick={() => (location.state ? navigate(-1) : navigate('/'))}
    >
      <div className={cl.modal} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}

export default Modal
