import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PhotoList from '../../components/Photo/PhotoList/PhotoList'

import { countFormatter } from '../../helpers/countFormatter'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { clearCollectionState } from '../../store/slices/collection/collectionSlice'
import { fetchCollectionPhotosById } from '../../store/thunk/collection'
import cl from './Collection.module.css'
import { LoaderCollectionPage } from '../../components/UI/Loaders'

const CollectionPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { collectionPhotos, collectionAbout, isLoading } = useAppSelector(
    (state) => state.collectionSlice
  )

  useEffect(() => {
    dispatch(fetchCollectionPhotosById({ id }))

    return () => {
      dispatch(clearCollectionState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      {isLoading ? (
        <LoaderCollectionPage />
      ) : (
        <div className={cl.collection}>
          <img
            className={cl.collection_bg}
            src={collectionAbout.preview_photos?.[0].urls.regular}
            alt=""
          />

          <div className={cl.collection_about}>
            <h2>{collectionAbout.title}</h2>
            <div className={cl.collection_user}>
              <img src={collectionAbout.user?.profile_image.medium} alt="" />
              <h4>{collectionAbout.user?.first_name}</h4>
            </div>
          </div>
        </div>
      )}

      <div className={cl.photo_list}>
        <div className={cl.collection_total}>
          {countFormatter(collectionAbout.total_photos)} photos
        </div>

        <PhotoList photos={collectionPhotos} isLoading={isLoading} />
      </div>
    </>
  )
}

export default CollectionPage
