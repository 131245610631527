import axios from 'axios'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useImgSize } from '../../../hooks/useImgSize'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { IPhotoItem_API } from '../../../types/api'
import { IPhotoItem } from '../../../types/photo'
import DesktopPhotoItem from './Desktop/DesktopPhotoItem'
import MobilePhotoItem from './Mobile/MobilePhotoItem'
import { API_URL } from '../../../store/thunk/api'
dayjs.extend(localizedFormat)

interface IPhotoItemProps {
  closeBtn?: 'hidden'
}

const PhotoItem: FC<IPhotoItemProps> = ({ closeBtn }) => {
  const id = useParams().id as string

  const [currImg, setCurrImg] = useState<IPhotoItem | null>(null)
  const imgSize = useImgSize(currImg)
  const windowSize = useWindowSize()

  useEffect(() => {
    axios.get<IPhotoItem_API>(`${API_URL}/photos/${id}`).then((data) => setCurrImg(data.data.data))
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const downloadPhotoHandler = async () => {
    if (currImg) {
      const imageName = `${currImg?.user.username}-${currImg?.id}`
      const response = await fetch(`${API_URL}/photos/${currImg.id}/download/${imageName}`)

      if (response.status === 200) {
        const blob = await response.blob()
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.href = downloadUrl
        link.download = imageName
        document.body.appendChild(link)
        link.click()
        link.remove()
        URL.revokeObjectURL(downloadUrl)
      }
    }
  }

  return (
    <>
      {windowSize.width > 760 ? (
        <DesktopPhotoItem
          currImg={currImg}
          closeBtn={closeBtn}
          downloadPhotoHandler={downloadPhotoHandler}
          imgSize={imgSize}
        />
      ) : (
        <MobilePhotoItem
          currImg={currImg}
          closeBtn={closeBtn}
          downloadPhotoHandler={downloadPhotoHandler}
          imgSize={imgSize}
        />
      )}
    </>
  )
}

export default PhotoItem
