import { PropsWithChildren } from 'react'
import SvgIcon, { SvgIconsProps } from '../SvgIcon/SvgIcon'

interface ICustomBlockProps extends SvgIconsProps {
  text?: string
  className?: string
  hideif?: any
  onClick?: () => void
}

const CustomBlock = (props: PropsWithChildren<ICustomBlockProps>) => {
  const { children, text, className, hideif = true, icon, ...other } = props

  return (
    <>
      {hideif ? (
        <div className={className} {...other}>
          {icon && <SvgIcon icon={icon} {...props} />}
          {text}
          {children}
        </div>
      ) : null}
    </>
  )
}

export default CustomBlock
