import { useEffect, useRef } from 'react'

export const useDisableScroll = (isLoading?: any) => {
  const scrollRef = useRef<any>(null)

  useEffect(() => {
    if (!isLoading) window.document.body.style.overflow = 'hidden'

    if (scrollRef.current && !isLoading) scrollRef.current.style.overflow = 'hidden'
    if (scrollRef.current && isLoading) scrollRef.current.style.overflow = ''

    return () => {
      if (!isLoading) window.document.body.style.overflow = ''

      if (scrollRef.current) {
        scrollRef.current.style.overflow = ''
        scrollRef.current = null
      }
    }
  }, [isLoading])

  return scrollRef
}
