import cl from './LoaderIcon.module.css'

const LoaderIcon = () => {
  return (
    <div className={cl.loader}>
      <span></span>
    </div>
  )
}

export default LoaderIcon
