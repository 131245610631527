import { FC, useEffect } from 'react'
import CollectionsList from '../../components/CollectionsList/CollectionsList'
import { useAppDispatch } from '../../hooks/store'
import { fetchCollectionsBySearch } from '../../store/thunk/search'
import { ICollection } from '../../types/collection'
import cl from './SearchPage.module.css'

interface SearchPageCollectionsProps {
  collections: ICollection[]
  searchQuery: string
  isLoading: boolean
  params: string
  page: { currPage: number; limitPerPage: string }
}

const SearchPageCollections: FC<SearchPageCollectionsProps> = ({
  collections,
  searchQuery,
  params,
  isLoading,
  page,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (collections.length === 0 || searchQuery !== params)
      dispatch(fetchCollectionsBySearch({ query: params, page: page.currPage }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <div className={cl.collection_list}>
      <CollectionsList collections={collections} isLoading={isLoading} />
    </div>
  )
}

export default SearchPageCollections
