import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { clearSearchState } from '../../store/slices/search/searchSlice'
import cl from './SearchPage.module.css'
import SearchPageCollections from './SearchPageCollections'
import SearchPagePhotos from './SearchPagePhotos'
import SearchPageUsers from './searchPageUsers'

const searchSubHeader = {
  PHOTOS: 'PHOTOS',
  COLLECTIONS: 'COLLECTIONS',
  USERS: 'USERS',
}

interface SearchPageProps {
  type: keyof typeof searchSubHeader
}

const SearchPage: FC<SearchPageProps> = ({ type }) => {
  const dispatch = useAppDispatch()
  const { photos, collections, users, page, isLoading, searchQuery } = useAppSelector(
    (state) => state.searchSlice
  )
  const id = useParams().id as string

  useEffect(() => {
    dispatch(clearSearchState())

    return () => {
      dispatch(clearSearchState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className={cl.search_page}>
      <div className={cl.search_about}>
        <h2>{id}</h2>
      </div>

      {type === searchSubHeader.PHOTOS ? (
        <SearchPagePhotos
          photos={photos.items}
          searchQuery={searchQuery}
          params={id}
          page={page}
          isLoading={isLoading}
        />
      ) : type === searchSubHeader.COLLECTIONS ? (
        <SearchPageCollections
          collections={collections.items}
          searchQuery={searchQuery}
          isLoading={isLoading}
          params={id}
          page={page}
        />
      ) : type === searchSubHeader.USERS ? (
        <SearchPageUsers
          users={users.items}
          searchQuery={searchQuery}
          isLoading={isLoading}
          params={id}
          page={page}
        />
      ) : null}
    </div>
  )
}

export default SearchPage
