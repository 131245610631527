import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ITags } from '../../types/collection'
import cl from './TagList.module.css'

interface ITagListProps {
  tags: ITags[]
}

// TODO: add loader
const TagList: FC<ITagListProps> = ({ tags }) => {
  const navigate = useNavigate()

  const clickHandler = (tag: any) => navigate(`/s/photos/${tag.title}`)

  return (
    <div className={cl.tags}>
      {tags.map((tag, i) => (
        <div key={tag.title + i} onClick={() => clickHandler(tag)} className={cl.tag_item}>
          {tag.title}
        </div>
      ))}
    </div>
  )
}

export default TagList
