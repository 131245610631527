import { useEffect } from 'react'
import PhotoList from '../../components/Photo/PhotoList/PhotoList'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { clearPhotoState } from '../../store/slices/photo/photoSlice'
import { fetchPhotoList } from '../../store/thunk/photo'
import cl from './HomePage.module.css'

const HomePage = () => {
  const { photos, isLoading } = useAppSelector((state) => state.photoSlice)
  // const [page, setPage] = useState(1)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPhotoList({ page: 1 }))

    return () => {
      dispatch(clearPhotoState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={cl.home_page}>
      <PhotoList photos={photos} isLoading={isLoading} />

      {/* <button
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setPage((prev) => prev + 1)
        }}
      >
        Load more
      </button> */}
    </div>
  )
}

export default HomePage
