import { useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import OptimizedImage from '../../components/OptimizedImage/OptimizedImage'
import PhotoList from '../../components/Photo/PhotoList/PhotoList'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { useScrollTo } from '../../hooks/useScrollTo'
import { clearTopicState } from '../../store/slices/topic/topicSlice'
import { fetchTopicPhotosById } from '../../store/thunk/topic'
import cl from './Topic.module.css'
import { LoaderTopicPage } from '../../components/UI/Loaders'

const TopicPage = () => {
  const { slug } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const dispatch = useAppDispatch()
  const { topicList, topicPhotos, topicAbout, isLoading } = useAppSelector(
    (state) => state.topicSlice
  )

  useScrollTo(0, slug)

  useEffect(() => {
    if (topicList.length) {
      const topicIsInclude = topicList.find((t) => t.slug === slug)

      if (topicIsInclude) {
        dispatch(fetchTopicPhotosById({ id: slug }))
      } else {
        navigate('/')
      }
    }

    return () => {
      dispatch(clearTopicState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, topicList])

  return (
    <>
      {isLoading ? (
        <LoaderTopicPage />
      ) : (
        <div className={cl.topic_main}>
          <OptimizedImage photo={topicPhotos[0]} />

          <div className={cl.topic_about}>
            <h2>{topicAbout.title}</h2>
            <p>{topicAbout.description}</p>
          </div>

          <div className={cl.topic_about_bottom}>
            <span>
              <Link to={`/photo/${topicPhotos[0]?.id}`} state={{ previousLocation: location }}>
                Photo
              </Link>{' '}
              by{' '}
              <a href={topicAbout?.cover_photo?.user?.links.html}>
                {topicAbout?.cover_photo?.user.first_name}
              </a>
            </span>
            <span>
              Read more about the <a href="https://unsplash.com/license">Unsplash License</a>
            </span>
            <span>
              This app use <a href="https://unsplash.com/developers">Unsplash API</a>
            </span>
          </div>
        </div>
      )}
      <div className={cl.photo_list}>
        <PhotoList photos={topicPhotos} isLoading={isLoading} />
      </div>
    </>
  )
}

export default TopicPage
