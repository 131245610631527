import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ICollectionPhotosById_API } from '../../types/api'
import { errorHandler } from './helpers/errorHandler'
import { API_URL } from './api'

export const fetchCollectionPhotosById = createAsyncThunk(
  'collection/fetchCollectionPhotosById',
  async ({ id }: { id?: number | string }, { fulfillWithValue }) => {
    const response = await axios.get<ICollectionPhotosById_API>(`${API_URL}/collections/${id}`)

    errorHandler(response)

    return fulfillWithValue(response.data)
  }
)
