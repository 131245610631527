import cl from './LoaderPhotoList.module.css'

export const LoaderPhotoList = () => {
  return (
    <div className={cl.pre_loader}>
      {new Array(10).fill('Do magic..').map((_, i) => (
        <div key={i} className={cl.skeleton}></div>
      ))}
    </div>
  )
}
