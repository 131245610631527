import { FC, useEffect } from 'react'
import { LoaderSearchPageUsers } from '../../components/UI/Loaders'
import UserList from '../../components/UserList/UserList'
import { useAppDispatch } from '../../hooks/store'
import { fetchUsersBySearch } from '../../store/thunk/search'
import { IUser } from '../../types/user'
import cl from './SearchPage.module.css'

interface SearchPageUsersProps {
  users: IUser[]
  searchQuery: string
  isLoading: boolean
  params: string
  page: { currPage: number; limitPerPage: string }
}

const SearchPageUsers: FC<SearchPageUsersProps> = ({
  users,
  searchQuery,
  params,
  isLoading,
  page,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (users.length === 0 || searchQuery !== params)
      dispatch(fetchUsersBySearch({ query: params, page: page.currPage }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <>
      {isLoading ? (
        <LoaderSearchPageUsers />
      ) : (
        <div className={cl.user_list}>
          <UserList users={users} />
        </div>
      )}
    </>
  )
}

export default SearchPageUsers
