import { FC, useEffect } from 'react'
import PhotoList from '../../components/Photo/PhotoList/PhotoList'
import { useAppDispatch } from '../../hooks/store'
import { fetchPhotosBySearch } from '../../store/thunk/search'
import { IPhoto } from '../../types/photo'
import cl from './SearchPage.module.css'

interface SearchPagePhotosProps {
  photos: IPhoto[]
  searchQuery: string
  params: string
  page: { currPage: number; limitPerPage: string }
  isLoading: boolean
}

const SearchPagePhotos: FC<SearchPagePhotosProps> = ({
  photos,
  searchQuery,
  params,
  isLoading,
  page,
}) => {
  const dispatch = useAppDispatch()
  const getPhotos = (page: number = 1) => dispatch(fetchPhotosBySearch({ query: params, page }))

  useEffect(() => {
    if (photos.length === 0 || searchQuery !== params) getPhotos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <div className={cl.photo_list}>
      <PhotoList photos={photos} isLoading={isLoading} />
      <button>more inspiration</button>
    </div>
  )
}

export default SearchPagePhotos
