import { PropsWithChildren } from 'react'
import SvgIcon, { SvgIconsProps } from '../SvgIcon/SvgIcon'
import cl from './CustomButton.module.css'

interface ICustomButtonProps extends SvgIconsProps {
  text?: string
  hideif?: any
  className?: string
  disabled?: boolean
  onClick?: () => void
  reverse?: boolean
  cursor?: 'not-allowed'
}

const CustomButton = (props: PropsWithChildren<ICustomButtonProps>) => {
  const { children, text, icon, hideif, onClick, reverse, cursor } = props

  return (
    <>
      {hideif ? null : (
        <button className={cl.btn} onClick={onClick} {...props} style={{ cursor: cursor }}>
          {reverse ? (
            <>
              {icon && <SvgIcon icon={icon} {...props} />}
              <h4 className={cl.text}>{text}</h4>
            </>
          ) : (
            <>
              <h4 className={cl.text}>{text}</h4>
              {icon && <SvgIcon icon={icon} {...props} />}
            </>
          )}

          {children}
        </button>
      )}
    </>
  )
}

export default CustomButton
