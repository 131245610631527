import { useMemo, useState } from 'react'
import { IPhotoItem } from '../types/photo'

const formatBytes = (bytes: number) => {
  const unitsDecimal = {
    b_to_kb: (bytes: number) => Math.round(bytes * 0.001) + ' kb',
    b_to_mb: (bytes: number) => (bytes * 0.000001).toFixed(1) + ' mb',
  }

  if (bytes >= 100_000 && bytes < 1_000_000) return unitsDecimal.b_to_kb(bytes)

  if (bytes >= 1_000_000) return unitsDecimal.b_to_mb(bytes)

  return '?'
}

export const useImgSize = (img: IPhotoItem | null) => {
  const [imgSize, setImgSize] = useState('')

  useMemo(async () => {
    if (img) {
      const responseImg = await fetch(img.urls.full)
      await responseImg.blob().then((blob) => setImgSize(formatBytes(blob.size)))
    }
  }, [img])

  return imgSize
}
