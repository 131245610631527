import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IPhoto } from '../../../types/photo'
import OptimizedImage from '../../OptimizedImage/OptimizedImage'
import { LoaderPhotoList } from '../../UI/Loaders'
import cl from './PhotoList.module.css'

interface ImageListProps {
  photos: IPhoto[]
  isLoading?: boolean
}

const PhotoList: FC<ImageListProps> = ({ photos, isLoading }) => {
  const location = useLocation()

  return (
    <>
      {isLoading ? (
        <LoaderPhotoList />
      ) : (
        <div className={cl.photo_list}>
          {photos.map((photo) => (
            <Link key={photo.id} to={`/photos/${photo.id}`} state={{ previousLocation: location }}>
              <OptimizedImage photo={photo} />
            </Link>
          ))}
        </div>
      )}
    </>
  )
}

export default PhotoList
