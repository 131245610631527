import { FC } from 'react'
import { Link } from 'react-router-dom'
import { IUser } from '../../types/user'
import UserPreviewPhotos from './UserItem'
import cl from './UserList.module.css'

interface IUserListProps {
  users: IUser[]
}

const UserList: FC<IUserListProps> = ({ users }) => {
  return (
    <ul className={cl.user_list}>
      {users.map((user) => (
        <li key={user.id}>
          <Link to={''} className={cl.user_item}>
            <div className={cl.about_user}>
              <div className={cl.user_img}>
                <img src={user.profile_image.large} alt="avatar" />
              </div>
              <div className={cl.user_info}>
                <h4>{user.first_name}</h4>
                <h3>{user.username}</h3>
              </div>
            </div>

            <UserPreviewPhotos userPhotos={user.photos} />
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default UserList
