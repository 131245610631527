import { useAppSelector } from '../../hooks/store'
import { useWindowSize } from '../../hooks/useWindowSize'
import HeaderDesktop from './Desktop/HeaderDesktop'
import HeaderMobile from './Mobile/HeaderMobile'

const Header = () => {
  const { searchQuery } = useAppSelector((state) => state.searchSlice)
  const { collectionAbout } = useAppSelector((state) => state.collectionSlice)

  const windowSize = useWindowSize()

  if (windowSize.width > 760) return <HeaderDesktop searchQuery={searchQuery} />

  return <HeaderMobile collectionAbout={collectionAbout} searchQuery={searchQuery} />
}

export default Header
